.tabs-container {
  display: flex;
  align-items: center;
  padding: 10px;
}

.tabs {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  flex-grow: 1;
}

.tab {
  padding: 10px;
  cursor: pointer;
  text-align: center;
  width: 80px;
  flex-shrink: 0;
  /* Prevent tabs from shrinking */
}

.tab.active {
  background-color: #4a90e2;
  color: white;
}

.filter-container {
  display: flex;
  align-items: center;
  margin-left: auto;
  /* Push the filter icon to the right */
}

.filter-icon,
.clear-filter-icon {
  cursor: pointer;
  margin-left: 10px;
}