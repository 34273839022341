.header-container {
  width: 100%;
  background-color: #4a90e2; /* Adjust this color as needed */
  padding: 10px 0;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.header-text {
  color: white;
  margin: 0;
  font-size: 1.25em;
  font-weight: bold;
}

.ticker-container {
  width: 100%;
  overflow: hidden;
  background-color: darkred;
  border: black 1px solid;
}

.ticker {
  display: flex;
  width: 100%;
  animation: ticker 15s linear infinite; /* Adjusted duration for smoother scrolling */
}

.ticker-text {
  white-space: nowrap;
  padding-left: 100%; /* Start from the right */
  color: white;
  font-size: 1em;
  font-weight: bold;
}

@keyframes ticker {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-180%);
  }
}
