.schedule-list {
  padding: 0px !important;
}

.schedule-section {
  padding: 20px;
}

.schedule-time-group {
  margin-bottom: 20px;
}

.schedule-time-group h3 {
  margin-bottom: 10px;
  color: #4a90e2;
  font-weight: bold;
}

.schedule-time-group h3:before {
  content: "—— ";
  color: #4a90e2;
  margin-left: -20px;
}

.schedule-section:has(div.current-blocks) {
  background-color: #DFF7F9;
}


.current-blocks {
  /*foo*/
  border: 0px;
}

.schedule-item {
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease;
}

.schedule-item:hover {
  background-color: #e0e0e0;
}

.schedule-item h4 {
  margin: 0;
  font-weight: bold;
}

.schedule-detail {
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 1000;
  background-color: white;
}

.guest-list {
  display: flex;
  flex-wrap: wrap;
}

.guest-item {
  padding: 5px 10px;
  margin: 5px;
  background-color: #eee;
  border-radius: 5px;
  cursor: pointer;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #357ab8;
}