@font-face {
  font-family: 'AvenirNextLTPro';
  src: url('./fonts/AvenirNextLTPro-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextLTPro';
  src: url('./fonts/AvenirNextLTPro-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextLTPro';
  src: url('./fonts/AvenirNextLTPro-It.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

body,
html {
  font-family: 'AvenirNextLTPro', Arial, sans-serif !important;
}

body,
html,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  color: black;
  font-family: Arial, sans-serif;
  font-family: 'AvenirNextLTPro', Arial, sans-serif !important;
  overflow: hidden;
  /* Prevents the body from scrolling */
}

.header {
  height: 100px;
  width: 100%;
  background-image: url('https://i.ytimg.com/vi/W4YzuPOkK-Q/hqdefault.jpg');
  background-size: cover;
  background-position: center;
}

.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  /* Account for header height */
  overflow: hidden;
}

.tabs {
  display: contents;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  /* Prevents overflow of the tabs */
}

.tab-panels {
  flex: 1;
  overflow-y: auto;
  /* Allows the tab panels to scroll */
  padding-bottom: 60px;
  /* Extra padding to account for the fixed bottom tab navigation */
}

.tab-list {
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  z-index: 1000;
  height: 50px;
  /* Height of the tab list */
}

.tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex-grow: 1;
  /* Ensures each tab takes up an equal amount of space */
  max-width: 60px;
  /* Adjust this value as needed to reduce the tab width */
}

.tab svg {
  width: 20px;
  height: 20px;
}

.tab-text {
  font-size: 0.7em;
}

.tab.active {
  background-color: #4a90e2;
  color: white;
}