.guest-detail {
  padding: 20px;
  color: white;
}

.guest-detail .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: white;
}

.guest-detail .close-button:hover {
  color: #357ab8;
}

.guest-detail .guest-detail-image {
  width: 80%;
  max-width: 300px;
  height: auto;
  border-radius: 10px;
}

.guest-detail .guest-detail-name {
  font-size: 2em;
  margin-top: 10px;
}

.guest-detail .guest-detail-bio {
  margin-top: 20px;
  line-height: 1.6;
}

.guest-detail .back-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.guest-detail .back-button:hover {
  background-color: #357ab8;
}
