@font-face {
  font-family: 'AvenirNextLTPro';
  src: url('./fonts/AvenirNextLTPro-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

body,
html {
  font-family: 'AvenirNextLTPro', Arial, sans-serif !important;
}