.tabs-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  /* Optional: to visualize fixed header */
  position: sticky;
  top: 0;
  z-index: 1000;
}

.filter-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 10px;
}

.filter-container {
  display: flex;
  align-items: center;
}

.filter-icon,
.clear-filter-icon {
  cursor: pointer;
  margin-left: 10px;
}

.filter-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #f9f9f9;
  /* Optional: to visualize fixed header */
  position: sticky;
  top: 50px;
  /* Adjust based on the height of the tabs-container */
  z-index: 999;
}

.filter-item {
  padding: 5px 10px;
  margin: 5px;
  background-color: #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-item.selected {
  background-color: #4a90e2;
  color: white;
}

.filter-item:hover {
  background-color: #bbb;
}

.tabs {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  flex-grow: 1;
}

.tab {
  padding: 10px;
  cursor: pointer;
  text-align: center;
  width: 80px;
  flex-shrink: 0;
  font-weight: bold;
}

.tab.active {
  background-color: #4a90e2;
  color: white;
}

.schedule-list-container {
  flex: 1;
  overflow-y: auto;
}

.schedule-list {
  padding: 20px;
}

.schedule-time-group {
  margin-bottom: 20px;
}

.schedule-time-group h3 {
  margin-bottom: 10px;
  color: #4a90e2;
}

.schedule-item {
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease;
}

.schedule-item:hover {
  background-color: #e0e0e0;
}

.schedule-item h4 {
  margin: 0;
}

.schedule-item-detail {
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 1000;
  background-color: white;
}

.schedule-item-detail h2 {
  margin-top: 0;
  color: #4a90e2;
}

.schedule-item-detail p {
  margin: 5px 0;
}

.guest-list {
  display: flex;
  flex-wrap: wrap;
}

.guest-item {
  padding: 5px 10px;
  margin: 5px;
  background-color: #eee;
  border-radius: 5px;
  text-align: center;
}

.guest-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.close-button:hover {
  color: #357ab8;
}

.back-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #357ab8;
}

.header-container {
  margin-bottom: 0px !important;
}