.guests-list {
  padding-left: 11px;
  background-color: #f9f9f9;
  background-color: black;
  padding-right: 15px;
}

.guest-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #ffffff;
  /* White background for list items */
  margin-bottom: 10px;
  /* Spacing between list items */
  border-radius: 8px;
  /* Rounded corners for list items */
}

.guest-item:hover {
  background-color: #e0e0e0;
  /* Slightly darker on hover */
}

.guest-image {
  width: 100px;
  height: 100px;
  margin-right: 16px;
  object-fit: cover;
  border-radius: 50%;
  /* Circular image */
}

.guest-name {
  font-size: 1.25em;
  color: #333;
  /* Darker text color */
}