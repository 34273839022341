.schedule-item-detail {
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    z-index: 1000;
    background-color: white;
  }
  
  .schedule-item-detail h2 {
    margin-top: 0;
    color: #4a90e2;
  }
  
  .schedule-item-detail p {
    margin: 5px 0;
  }
  
  .guest-list {
    display: flex;
    flex-wrap: wrap;
  }
  
  .guest-item {
    padding: 5px 10px;
    margin: 5px;
    background-color: #eee;
    border-radius: 5px;
    text-align: center;
  }
  
  .guest-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
  }
  
  .close-button:hover {
    color: #357ab8;
  }
  
  .back-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .back-button:hover {
    background-color: #357ab8;
  }
  