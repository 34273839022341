.faq-item {
  margin-bottom: 20px;
}

.faq-item input,
.faq-item textarea {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.faq-item label {
  display: block;
  margin-bottom: 10px;
}

button {
  padding: 10px 20px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #357ab8;
}